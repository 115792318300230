// slices/contentfulSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '@/lib/contenful'; // Import Contentful client

export const fetchContentfulData = createAsyncThunk(
    'contentful/fetchData',
    async () => {
        try {

            const contents = {
                blog: {},
                faqs: {},
                eventShowcasedList: [],
                candidateInfoHeroSection: [],
                candidateReviews: [],
                clientTestimonials: [],
                whyTacniqueList: [],
                challengeList: [],
                secondaryList: []
            };

            const mapContentFields = (content) => {
                switch (content.sys.contentType.sys.id) {
                    case 'challengeList':
                        contents.challengeList.push(content.fields);
                        break;
                    case 'candidateInfoHeroSection':
                        contents.candidateInfoHeroSection.push(content.fields);
                        break;
                    case 'eventShowcasedList':
                        contents.eventShowcasedList.push(content.fields);
                        break;
                    case 'frequentlyAskedQuestions':
                        if (!contents.faqs[content.fields.type]) {
                            contents.faqs[content.fields.type] = [];
                        }
                        contents.faqs[content.fields.type].push(content.fields);
                        break;
                    case 'secondaryList':
                        contents.secondaryList.push(content.fields);
                        break;
                    case 'candidateReviews':
                        contents.candidateReviews.push(content.fields);
                        break;
                    case 'whyTacniqueList':
                        contents.whyTacniqueList.push(content.fields);
                        break;
                    case 'testomonial':
                        contents.clientTestimonials.push(content.fields);
                        break;
                    default:
                        contents[content.sys.id] = content.fields;
                }
            };

            const response = await client.getEntries();
            response.items.forEach(mapContentFields);

            return contents
        } catch (err) {
            console.log(err.message)
        }
    }
);

const contentfulSlice = createSlice({
    name: 'contentful',
    initialState: {
        data: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContentfulData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContentfulData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchContentfulData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const selectContentfulData = (state) => state.contentful.data;

export default contentfulSlice.reducer;
