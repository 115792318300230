import { useEffect, useState } from "react";
import Notification from "@/components/Notification";
const NetworkStatus = () => {
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        const button = document.getElementsByTagName("button");
        window.addEventListener("online", () => {
            setNotification(null);
            for (let i = 0; i < button.length; i++) {
                button[i].disabled = false;
            }
        });
        window.addEventListener("offline", () => {
            setNotification({
                message: "No Internet Connection",
                type: "error",
            });
            for (let i = 0; i < button.length; i++) {
                button[i].disabled = true;
                if (
                    !button[i].classList.contains("disabled:cursor-not-allowed")
                ) {
                    button[i].classList.add("disabled:cursor-not-allowed");
                }
            }
        });
    }, []);

    return (
        <>
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                />
            )}
        </>
    );
};

export default NetworkStatus;
