import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "@/services/api";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import queryParams from "@/services/slices/queryParamsSlice";
import otherReducers from "@/services/slices/otherReducersSlice";
import userReducers from "@/services/slices/usersSlice";
import insightReducers from "@/services/slices/insightsSlice";
import notificationReducers from "@/services/slices/notificationSlice";
import subInsightReducers from "@/services/slices/subInsightsSlice";
import countryReducer from "@/services/slices/countriesSlice";
import requirementsReducer from "@/services/slices/requirementsSlice";
import skillsReducer from "@/services/slices/skillsSlice";
import InterviewersReducer from "@/services/slices/interviewerSlice";
import contentfulReducers from "@/services/slices/contentful.slice";

const reducers = combineReducers({
    api: api.reducer,
    queryParams,
    user: userReducers,
    other: otherReducers,
    insights: insightReducers,
    subInsight: subInsightReducers,
    notification: notificationReducers,
    country: countryReducer,
    requirements: requirementsReducer,
    skills: skillsReducer,
    interviewers: InterviewersReducer,
    contentful: contentfulReducers,
});

const masterReducer = (state, action) => {
    if (action.type !== HYDRATE) {
        return reducers(state, action);
    }

    return {
        ...state,
        queryParams: {
            ...action.payload.queryParams.queryParams,
        },
    };
};

export const makeStore = () =>
    configureStore({
        reducer: masterReducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware().concat(api.middleware);
        },
    });

export const store = makeStore();

export const wrapper = createWrapper(makeStore); // add (makeStore, { debug: true }) to check server side redux store.
