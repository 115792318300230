import React from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useCDNUrl, useWriteKey } from "@/hooks/useSegmentConfig";

const AnalyticsContext = React.createContext(undefined);

export const AnalyticsProvider = ({ children }) => {
    const [writeKey, setWriteKey] = useWriteKey();
    const [cdnURL, setCDNUrl] = useCDNUrl();
    const analytics = React.useMemo(() => {
        return AnalyticsBrowser.load({ writeKey, cdnURL });
    }, [writeKey, cdnURL]);
    return React.createElement(
        AnalyticsContext.Provider,
        { value: { analytics, writeKey, setWriteKey, cdnURL, setCDNUrl } },
        children
    );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
    const result = React.useContext(AnalyticsContext);
    if (!result) {
        throw new Error("Context used outside of its Provider!");
    }
    return result;
};
