import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    queryParams: {},
};

export const queryParamSlice = createSlice({
    name: "queryParams",
    initialState,
    reducers: {
        addQueryParams: (state, action) => {
            state.queryParams = action.payload;
        },
    },
});

export const { addQueryParams } = queryParamSlice.actions;

export default queryParamSlice.reducer;
