import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation,
    faCircleXmark,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Notification = ({ message, secondaryMessage, type }) => {
    const [show, setShow] = useState(true);

    let typeIcon;
    switch (type) {
        case "success":
            typeIcon = (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="flex-shrink-0 h-5 w-5 text-green-400"
                    aria-hidden="true"
                />
            );
            break;

        case "error":
            typeIcon = (
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="flex-shrink-0 h-5 w-5 text-red-400"
                    aria-hidden="true"
                />
            );
            break;

        case "info":
        default:
            typeIcon = (
                <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="flex-shrink-0 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                />
            );
            break;
    }

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-40"
        >
            <div className="flex w-full flex-col items-center space-y-4">
                <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-center">
                                <div className="flex-shrink-0 mt-0.5">
                                    {typeIcon}
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p
                                        className="text-sm font-medium text-gray-900"
                                        data-test-id="notification-message"
                                    >
                                        {message}
                                    </p>
                                    {secondaryMessage && (
                                        <p
                                            className="mt-1 text-sm text-gray-500"
                                            data-test-id="notification-secondary-message"
                                        >
                                            {secondaryMessage}
                                        </p>
                                    )}
                                </div>
                                <div className="ml-4 flex flex-shrink-0 hidden">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                        onClick={() => {
                                            setShow(false);
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className="flex-shrink-0 h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default Notification;
