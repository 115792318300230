import { classNames } from "@/utilities";

const Alert = ({ message, validationId = "general", extraClasses }) => {
    return (
        <div className={classNames("rounded-md p-1", extraClasses)}>
            <div className="flex items-center gap-1">
                <div className="flex-shrink-0">
                    {/* <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="h-2.5 w-2.5 mr-1 text-red-500"
                        aria-hidden="true"
                    /> */}
                </div>
                <div className="text-xs text-red-500">
                    <p data-test-id={`${validationId}-validation-error`}>
                        {message}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Alert;
