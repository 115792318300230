const getEnvironmentVariable = (environmentVariable = '') => {
    const unvalidatedEnvironmentVariable = environmentVariable;
    if (!unvalidatedEnvironmentVariable) {
        throw new Error(
            `Couldn't find environment variable: ${environmentVariable}`
        );
    } else {
        return unvalidatedEnvironmentVariable;
    }
};

export const config = {
    contenfulSpace: getEnvironmentVariable(process.env.NEXT_PUBLIC_CONTENTFUL_SPACE),
    apiKey: getEnvironmentVariable(process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS),
};
