// src/api/postsApi.js

import { createApi } from "@reduxjs/toolkit/query/react";
import { GraphQLClient } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

const client = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    {
        credentials: "include",
        headers: {
            Accept: "application/json",
        },
    }
);

const baseQuery = graphqlRequestBaseQuery({
    client,
});

export const api = createApi({
    baseQuery,
    tagTypes: [
        "getCandidates",
        "unlockCandidate",
        "getUserInterviews",
        "getInterviewData",
        "getCandidateInterview",
        "getQuestions",
        "getInterviewTypes",
        "getRequirementsByActiveState",
        "getCurrentUser",
    ],
    endpoints: () => ({}),
});
