const contentful = require("contentful");
const {config} = require("./config");

const client = contentful.createClient({
    space: config.contenfulSpace || '',
    environment: "master", // defaults to 'master' if not set
    accessToken: config.apiKey || '',
});

export default client;
