import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hideBanner: false,
};

export const otherReducers = createSlice({
    name: "otherReducers",
    initialState,
    reducers: {
        hideBannerStatus: (state, action) => {
            state.hideBanner = action.payload;
        },
    },
});

export const { hideBannerStatus } = otherReducers.actions;

export default otherReducers.reducer;
