import { createSlice } from "@reduxjs/toolkit";

const interviewerSlice = createSlice({
    name: "allInterviewersList",
    initialState: {
        interviewers: [],
    },
    reducers: {
        setInterviewer(state, action) {
            state.interviewers = action.payload;
        },
    },
});

export const { setInterviewer } = interviewerSlice.actions;

export default interviewerSlice.reducer;