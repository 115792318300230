import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editedInsights: [],
};

export const subInsightsSlice = createSlice({
    name: "subInsight",
    initialState,
    reducers: {
        editEditedInsights: (state, action) => {
            const { value, editedInsights } = action.payload;
            const index = editedInsights.findIndex(
                (item) =>
                    item.insightKey === value.insightKey &&
                    item.groupKey === value.groupKey
            );
            if (index === -1) {
                const temp = [...state.editedInsights];
                temp.push(value);
                state.editedInsights = temp;
            } else {
                const temp = [...editedInsights];
                temp[index] = value;
                state.editedInsights = temp;
            }
        },
        removeEditedInsights: (state, action) => {
            state.editedInsights = state.editedInsights.filter(
                (insight) => insight.id !== action.payload.id
            );
        },
        clearEditedInsights: (state) => {
            state.editedInsights = [];
        },
    },
});

export const getInsightState = (state) => state.subInsight.editedInsights;

export const { editEditedInsights, removeEditedInsights, clearEditedInsights } =
    subInsightsSlice.actions;

export default subInsightsSlice.reducer;
