// countrySlice.js
import { createSlice } from "@reduxjs/toolkit";

const requirementsSlice = createSlice({
    name: "allRequirementsList",
    initialState: {
        requirements: [],
    },
    reducers: {
        setRequirements(state, action) {
            state.requirements = action.payload;
        },
    },
});

export const { setRequirements } = requirementsSlice.actions;

export default requirementsSlice.reducer;
