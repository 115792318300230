import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editedInsights: [],
    isAnswerModalOpen: false,
    pinnedQuestion: null,
};

export const insightsSlice = createSlice({
    name: "insights",
    initialState,
    reducers: {
        editEditedInsights: (state, action) => {
            const { insight, editedInsights } = action.payload;
            const index = editedInsights.findIndex(
                (item) =>
                    item.insight_key === insight.insight_key &&
                    item.question_key === insight.question_key
            );
            if (index === -1) {
                const temp = [...editedInsights];
                temp.push(insight);
                state.editedInsights = temp;
            } else {
                const temp = [...editedInsights];
                temp[index] = insight;
                state.editedInsights = temp;
            }
        },
        clearEditedInsights: (state) => {
            state.editedInsights = [];
        },
        pinQuestion: (state, action) => {
            state.pinnedQuestion = action.payload;
        },

        pinQuestionFail: (state) => {
            state.requestedPinnedQuestion = null;
        },

        resetPinnedQuestions: (state) => {
            state.pinnedQuestion = null;
        },
        resetModal: (state) => {
            state.isAnswerModalOpen = false;
        },
        toggleModal: (state) => {
            state.isAnswerModalOpen = !state.isAnswerModalOpen;
        },
        setModal: (state, action) => {
            state.isAnswerModalOpen = action.payload;
        },
    },
});
export const getQuestionState = (state) => state.insights;
export const {
    editEditedInsights,
    clearEditedInsights,
    pinQuestion,
    resetPinnedQuestions,
    resetModal,
    toggleModal,
    setModal,
    pinQuestionRequest,
    pinQuestionFail,
} = insightsSlice.actions;

export default insightsSlice.reducer;
