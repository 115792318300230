import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: null,
};

const notificationReducers = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.content = action.payload;
        },
        resetNotification: (state) => {
            state.content = null;
        },
    },
});

export const { setNotification, resetNotification } =
    notificationReducers.actions;

export default notificationReducers.reducer;
